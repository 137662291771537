<div class="container-fluid container-app">
    <div class="d-flex w-100 stolmax-container" [class.active]="isHome$ | async">
        <div class="d-flex w-100 stolmax-header">
            <app-header [active]="scrollPosition$ | async"></app-header>
        </div>
        <div class="d-flex w-100 stolmax-content" [class.active]="scrollPosition$ | async" #routerContent>
            <router-outlet>
            </router-outlet>
        </div>
    </div>
    <div class="d-flex align-items-end cookies-styles" *ngIf="!(rulesAccepted$ | async)">
        <p class="flex-grow-1"> Witryna Stol-max używa cookies. Jeżeli nie wyrażasz na to zgody możesz zablokować
            cookies w ustawieniach
            swojej
            przeglądarki internetowej lub opuścić stronę. Korzystając z strony akceptujesz
            <a [routerLink]="['/regulamin']">regulamin</a> i wyrażasz zgodę na przetwarzanie danych.
        </p>
        <div class="px-3 pb-2">
            <button mat-raised-button color="primary" (click)="setRulesAccept()">
                Akceptuję
            </button>
        </div>
    </div>
</div>