<nav class="navbar-stolmax d-flex align-items-center" [class.active]="active">
    <a [routerLink]="['']" class="logo" aria-label="Main/Home page">
        <img width="718" height="126" priority="high" alt="Crossed two green, red squares and green text 'STOL-MAX'"
            src="https://aniancep.sirv.com/web-images/stolmax-logo.webp">
    </a>
    <div class="desktop-header">
        <a [routerLink]="['/o-nas']" class="ms-5" routerLinkActive="active-link" mat-button
            aria-label="Informations about company">O NAS</a>
        <a [routerLink]="['/oferta']" routerLinkActive="active-link" mat-button aria-label="Company offer">OFERTA</a>
        <a [routerLink]="['/galeria']" routerLinkActive="active-link" mat-button
            aria-label="Gallery of furnitures">GALERIA</a>
        <a [routerLink]="['/kontakt']" routerLinkActive="active-link" mat-button
            aria-label="Contact form and map">KONTAKT</a>
        <a target="_blank" rel="nofollow" href="https://www.instagram.com/stolmax_tychy" aria-label="Instagram fanpage"
            class="social-icon ig">
            <img src="https://aniancep.sirv.com/web-images/instagram-logo-3286.svg" alt="Black icon of instagram" height="32px" width="32px">
        </a>
        <a target="_blank" rel="nofollow" aria-label="Facebook fanpage"
            href="https://www.facebook.com/profile.php?id=100063741032209" class="social-icon fb">
            <img src="https://aniancep.sirv.com/web-images/facebook-icon-1-svg.svg" alt="facebook icon in black color" height="32px" width="32px">
        </a>
    </div>
    <div class="mobile-header">
        <button mat-fab color="primary" [matMenuTriggerFor]="menu"
            aria-label="Button for open 'hamburger' menu where you are able to change pange">
            <mat-icon>menu</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button [routerLink]="['/o-nas']" routerLinkActive="active-link" mat-menu-item
                aria-label="Informations about company">O NAS</button>
            <button [routerLink]="['/oferta']" routerLinkActive="active-link" mat-menu-item
                aria-label="Company offer">OFERTA</button>
            <button [routerLink]="['/galeria']" routerLinkActive="active-link" mat-menu-item
                aria-label="Gallery of furnitures">GALERIA</button>
            <button [routerLink]="['/kontakt']" routerLinkActive="active-link" mat-menu-item
                aria-label="Contact form and map">KONTAKT</button>
            <a target="_blank" rel="nofollow" href="https://www.instagram.com/stolmax_tychy"
                aria-label="Instagram fanpage" mat-menu-item aria-label="Contact form and map">INSTAGRAM</a>
            <a target="_blank" rel="nofollow" href="https://www.facebook.com/profile.php?id=100063741032209"
                aria-label="Instagram fanpage" mat-menu-item aria-label="Contact form and map">FACEBOOK</a>
        </mat-menu>
    </div>
</nav>
